<template>
  <b-overlay
      :show="state.loading"
      rounded="sm"
  >
    <b-row>
      <b-col lg="12">
        <trading-view/>
      </b-col>
      <b-col lg="8">
        <line-chart
            :chart="volChart"
            :type="type"
        />
      </b-col>
      <b-col lg="4">
        <b-overlay
            :show="loading"
            rounded="sm"
        >
          <filter-chart
              :date="date"
              :sum="sum"
              :type="type"
              :duration.sync="duration"
              @next="next"
              @perv="perv"
              @crypto="setCrypto"
          />
        </b-overlay>
      </b-col>
      <b-col lg="12">
        <statistics-group/>
      </b-col>
      <template v-if="false">
        <b-col lg="4">
          <filter-chart2/>
        </b-col>
        <b-col lg="8">
          <line-chart2/>
        </b-col>
        <b-col lg="12">
          <statistics-group2/>
        </b-col>
      </template>
    </b-row>
  </b-overlay>

</template>

<script>
import {
  BRow,
  BCol,
  BOverlay,
} from 'bootstrap-vue'
import LineChart from "@/views/Markets/LineChart";
import StatisticsGroup from "@/views/Markets/StatisticsGroupVol";
import TradingView from "@/views/Markets/TradingView";
import FilterChart from "@/views/Markets/FilterChart";
import FilterChart2 from "@/views/Markets/FilterChart2";
import LineChart2 from "@/views/Markets/LineChart2";
import StatisticsGroup2 from "@/views/Markets/StatisticsGroup2";

const step = {
  'YEARLY': 'jYear',
  'MONTHLY': 'jMonth',
  'DAILY': 'day',
}

export default {
  name: "Statistics",
  components: {
    StatisticsGroup2,
    LineChart2,
    FilterChart2,
    FilterChart,
    TradingView,
    StatisticsGroup,
    BRow,
    BCol,
    LineChart,
    BOverlay
  },
  data() {
    return {
      volChart: '',
      sum: '',
      duration: 'DAILY',
      number: 0,
      date: '',
      dateParam: {
        from: '',
        to: ''
      },
      loading: false,
      type: false
    }
  },
  watch: {
    'duration'() {
      this.loading = true
      this.getChartData()
    }
  },
  methods: {
    setDate(date, num) {
      return num < 0 ? date.subtract(num, step[this.duration]) : date.add(num, step[this.duration])
    },
    firstDate(date) {
      return date.startOf(step[this.duration]).format(this.$dateFormat['en'])
    },
    lastDate(date) {
      return date.endOf(step[this.duration]).format(this.$dateFormat['en'])
    },

    setDuration(date) {
      this.dateParam.from = this.firstDate(date)
      this.dateParam.to = this.lastDate(date)
      if (this.duration === 'DAILY') {
        delete this.dateParam.to
      }
    },

    async getChartData() {

      let date = this.$jmoment()
      date = this.setDate(date, this.number)
      this.setDuration(date)

      const res = await this.$axios(this.$urls.find(e => e[0] === this.$route.query.s)[1] + '/trades/volume-chart', {
        params: {
          marketType: this.$route.params.id,
          type: this.type,
          ...this.dateParam
        }
      })
      this.volChart = res.data.baseDTO.arr
      this.date = res.data.baseDTO.time
      this.sum = res.data.baseDTO.sum
      this.loading = false
    },

    perv() {
      this.loading = true
      this.number--
      this.getChartData()
    },
    next() {
      this.loading = true
      this.number++
      this.getChartData()
    },
    setCrypto(e) {
      this.loading = true
      this.type = e;
      this.getChartData()
    }
  },
  created() {
    this.state.loading = true
    this.getChartData()
  }
}
</script>

<style scoped>

</style>
