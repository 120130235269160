<template>
  <b-card
      no-body
      class="card-statistics"
  >
    <b-card-body class="statistics-body">
      <b-row :class="{'flex-row-reverse':false}">
        <b-col
            v-for="(item,index) in statisticsItems"
            :key="index"
            md="2"
            sm="6"
            class="mb-2 mb-md-0"
            :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside

                class="mr-2"
            >
              <b-avatar
                  size="48"
                  :variant="item.color"
              >
                <feather-icon
                    size="24"
                    :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col md="2"
               sm="6"
               class="mb-2 mb-md-0">
          <b-media no-body>
            <b-media-aside

                class="mr-2"
            >
              <b-avatar
                  size="48"
                  variant="light-primary"
              >
                <feather-icon
                    size="24"
                    icon="DollarSignIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                معاملات
              </h4>
              <b-card-text class="font-small-3 mb-0">
                حجم
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  // BCardHeader,
  // BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from 'bootstrap-vue'
// import {kFormatter} from "@core/utils/filter";

export default {
  name: 'StatisticsGroupVol',
  components: {
    BRow,
    BCol,
    BCard,
    // BCardHeader,
    // BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'ClockIcon',
          color: 'light-primary',
          title: '',
          subtitle: 'در ساعت',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'RotateCwIcon',
          color: 'light-info',
          title: '',
          subtitle: 'در روز',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ClipboardIcon',
          color: 'light-danger',
          title: '',
          subtitle: 'در هفته',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'CalendarIcon',
          color: 'light-success',
          title: '',
          subtitle: 'در ماه',
          customClass: '',
        },
        {
          icon: 'BarChart2Icon',
          color: 'light-success',
          title: '',
          subtitle: 'در سال',
          customClass: '',
        },
      ],
    }
  },

  computed: {},

  async mounted() {
    this.state.loading = true

    let res = {
      hourlyVolume : 0,
      dailyVolume:0,
      weeklyVolume:0,
      monthlyVolume:0,
      yearlyVolume:0
    }
    let data
    for (const url of this.$urls) {
      if (typeof this.$route.params.id === "undefined") {
        [data,] = await this.$http.get(url[1] + '/trades/volumes')
      } else {
        data = await this.$axios(url[1] + '/trades/volumes', {params: {marketType: this.$route.params.id}})
      }
      for (const key in res){
        res[key] = res[key] + data.data.baseDTO[key]
      }
    }
    this.statisticsItems[0].title = this.$numFormatter(res.hourlyVolume)
    this.statisticsItems[1].title = this.$numFormatter(res.dailyVolume)
    this.statisticsItems[2].title = this.$numFormatter(res.weeklyVolume)
    this.statisticsItems[3].title = this.$numFormatter(res.monthlyVolume)
    this.statisticsItems[4].title = this.$numFormatter(res.yearlyVolume)
  }
}
</script>
