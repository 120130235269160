var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('VueTradingView',{key:_vm.$store.state.appConfig.layout.skin,staticStyle:{"height":"calc(100vh - 200px)"},attrs:{"options":{
                            autosize:true,
                            symbol: 'BINANCE:' + _vm.$coinUnit[_vm.$marketType2relatedCoin(_vm.coin)] + 'USDT',
                            timezone: 'Asia/Tehran',
                            theme: _vm.$store.state.appConfig.layout.skin==='dark'?'dark':'light',
                            style: '1',
                            locale: 'fa_IR',
                            enable_publishing: false,
                            withdateranges: true,
                            hide_side_toolbar: false,
                            allow_symbol_change: true,
                            }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }